import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import SearchBlock from "components/search/SearchBlock";
import { autobind } from "react-decoration";
import { page } from "components/page";
import gql from "graphql-tag";
import GraphqlFragment from "lib/GraphqlFragment";
import DataList from "components/list/DataList";
import DirectionsDisplayComp from "components/resource/DirectionsDisplayComp";
import DirectionsLayout from "components/DirectionsLayout";

const getDirectionsList = gql`
  query getDirectionsList($searchForm: DirectionsForm){
    getDirectionsList(Input: $searchForm){
      ${GraphqlFragment.defaultLayoutReturn}
    }
  }
  ${GraphqlFragment.defaultLayoutFragment} 
`;

@withI18next(["common"])
@page
class DirectionsList extends React.Component {
  constructor(props) {
    super(props);
    let state = this.getQueryState(props);
    this.state = state;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props !== props) {
      this.setQueryState(props);
    }
  }

  @autobind
  setQueryState(props) {
    let state = this.getQueryState(props);

    if (JSON.stringify(state) !== JSON.stringify(this.state)) {
      this.setState({
        ...state,
      });
    }
  }

  @autobind
  getQueryState(props) {
    let { search, readerStore } = props;
    let params = { ...search };
    let { pageNo, limit, resourceType } = params;

    pageNo = pageNo || 1;
    limit = limit || 10;

    let searchForm = {
      pageNo,
      limit,
      resourceType,
    };

    Object.keys(searchForm).map((key) => {
      if (
        searchForm[key] === null ||
        (typeof searchForm[key] === "string" && searchForm[key].trim() === "")
      ) {
        delete searchForm[key];
      }
      return "";
    });

    let state = {
      searchForm: searchForm,
      auth: readerStore.auth,
      resourceType: resourceType,
    };

    return state;
  }

  render() {
    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accessKey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <h2>{this.props.t("jumperrwd.common.directions")}</h2>
              <a href="javascript:history.back();" className="back">
                {this.props.t("hyint.common.table.back")}
              </a>
              <div className="detail_block">
                <DataList
                  query={getDirectionsList}
                  variables={{
                    searchForm: this.state.searchForm,
                  }}
                  fetchPolicy={"network-only"}
                  displayComp={DirectionsDisplayComp}
                  pagination={"NormalPagination"}
                  customComponentLayout={DirectionsLayout}
                />
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default DirectionsList;
