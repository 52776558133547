import React from "react";

class DirectionsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="list">
          <ul>{this.props.compDataContent}</ul>
        </div>
      </>
    );
  }
}

DirectionsLayout.propTypes = {};

export default DirectionsLayout;
